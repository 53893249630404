import React, { type FC, useCallback, useState } from 'react';
import {
  useBi,
  useExperiments,
  useTranslation,
  type WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  type FormValues,
  type FormError,
  type FormActions,
  type FormResults,
  type FormConfig,
} from '@wix/form-viewer';
import { FormViewer, FormViewerContext } from '@wix/form-viewer/widget';
import styles from './index.scss';
import FormResultModal from './components/form-result-modal/form-result-modal';
import FormResultText from './components/form-result-text/form-result-text';
import { FILE_UPLOAD_SPEC } from '../experiments';

const CONTAINER_ID = 'form-viewer-example';
export type ControllerProps = {
  appSettings: any;
  formId: string;
  wfActions: FormActions;
  wfConfig: FormConfig;
  wfResults: FormResults;
};

interface FormResult {
  values?: FormValues;
  errors?: any[];
}

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const bi = useBi();
  const { experiments } = useExperiments();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [stepId, setStepId] = useState<string | undefined>(undefined);
  const [result] = useState<FormResult>({});

  const handleFormChange = useCallback((values: FormValues) => {
    setFormValues(values);
  }, []);

  const handleFormValidate = useCallback((errors: FormError[]) => {
    setFormErrors(errors);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const { i18n } = useTranslation();

  const externalData = experiments.enabled(FILE_UPLOAD_SPEC)
    ? {
        challengeId: 'a1950253-eb09-4cf0-8751-f0049cc28356',
        participantId: '70806d42-8f46-4a48-b26e-4a1751f25dc1',
      }
    : undefined;

  return (
    <div data-hook="FormExampleWidget-wrapper">
      <div id={CONTAINER_ID}>
        <div className={styles.rowContainer}>
          <div className={styles.itemContainer}>
            <FormViewerContext {...props}>
              <FormViewer
                onChange={handleFormChange}
                onValidate={handleFormValidate}
                values={formValues}
                errors={formErrors}
                i18n={i18n}
                formId={props.formId}
                bi={bi}
                externalData={externalData}
                stepId={stepId}
                onStepIdChange={setStepId}
              />
              {result.errors && (
                <FormResultText
                  errors={result.errors}
                  handleClick={openModal}
                />
              )}
              <FormResultModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                values={result.values}
                errors={result.errors}
              />
            </FormViewerContext>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
